import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { FiX } from "react-icons/fi"
import classNames from "classnames"

const Container = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.85rem 2.85rem;
  background-color: #eee;
  display: none;

  &.active {
    display: block;
  }
`

const Inner = styled.div`
  margin: 0 auto;
  padding: 2.6rem 1.0875rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-height: 600px) {
    padding: 1.6rem 1.0875rem;
  }

  @media (max-height: 500px) {
    padding: 0.5rem 0.3rem;
  }
`

const LogoWrapper = styled.div`
  margin-bottom: 40px;

  h1 {
    margin: 0;
  }
  a {
    color: #fb251b;
    text-decoration: none;
    font-size: 45px;
  }
`

const Logo = props => (
  <LogoWrapper>
    <h1>
      <Link to="/" onClick={props.clickHandler}>
        Quak
      </Link>
    </h1>
  </LogoWrapper>
)

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-size: 35px;

  @media (max-height: 800px) {
    font-size: 25px;
  }

  @media (max-height: 600px) {
    font-size: 20px;
  }

  ${props =>
    props.cta &&
    css`
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
    `}
`

const ListItem = styled.li`
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 0.02em;

  @media (max-height: 600px) {
    margin-bottom: 15px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const MenuLink = styled(Link)`
  color: black;

  &:hover {
    text-decoration: none;
  }
`

const External = styled.a`
  color: black;

  &:hover {
    text-decoration: none;
  }

  ${props =>
    props.signup &&
    css`
      padding: 0;
      background-color: #fb251b;
      padding: 8px 14px;
      color: white;
      transition: background-color 100ms ease-in-out;
      border-radius: 5px;

      &:hover {
        background-color: #333;
        color: white;
      }
    `}
`

const CloseButton = styled.div`
  cursor: pointer;
  display: inline-block;
  font-size: 40px;
  position: absolute;
  right: 30px;
  top: 30px;
  color: #777;
  line-height: 1;
  z-index: 99999;

  &:hover {
    color: #fb251b;
  }
`

const ExternalLink = props => (
  <ListItem>
    <External signup={props.signup} href={props.url} target="_blank">
      {props.children}
    </External>
  </ListItem>
)

const NavigationMobile = props => {
  function handleLinkClick(e) {
    props.setMobileNavVisibility(false)
  }

  function mainMenuElements() {
    const menu = [
      { url: "/clients/", text: "Clients" },
      { url: "/api/", text: "API" },
      { url: "/pricing/", text: "Pricing" },
      { url: "/faq/", text: "FAQ" },
    ]

    const elements = menu.map(item => (
      <ListItem key={item.url}>
        <MenuLink to={item.url} onClick={handleLinkClick}>
          {item.text}
        </MenuLink>
      </ListItem>
    ))

    return <>{elements}</>
  }

  return (
    <Container
      className={classNames({
        active: props.isVisible,
      })}
    >
      <CloseButton
        onClick={() => {
          props.setMobileNavVisibility(false)
        }}
      >
        <FiX />
      </CloseButton>
      <Inner>
        <Logo clickHandler={handleLinkClick} />

        <List>{mainMenuElements()}</List>

        <List cta>
          <ExternalLink url="https://app.quak.sh?login">Log in</ExternalLink>
          <ExternalLink signup url="https://app.quak.sh?signup">
            Sign up
          </ExternalLink>
        </List>
      </Inner>
    </Container>
  )
}

NavigationMobile.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setMobileNavVisibility: PropTypes.func.isRequired,
}

export default NavigationMobile
