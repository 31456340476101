import React, { useState, useEffect } from "react"
import NavigationHeader from "./navigation-header"
import NavigationMobile from "./navigation-mobile"

const Navigation = () => {
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false)

  useEffect(() => {
    // document.title = `current: ${isVisible}`
    setHtmlOverflow()
  })

  function setHtmlOverflow() {
    const html = document.getElementsByTagName("html")[0]
    if (!isMobileNavVisible) {
      html.removeAttribute("style")
    } else {
      html.setAttribute("style", "overflow: hidden;")
    }
  }

  return (
    <>
      <NavigationMobile
        isVisible={isMobileNavVisible}
        setMobileNavVisibility={setIsMobileNavVisible}
      />
      <NavigationHeader setMobileNavVisibility={setIsMobileNavVisible} />
    </>
  )
}

export default Navigation
