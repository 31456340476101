import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import media from "styled-media-query"
import { FiMenu } from "react-icons/fi"

const breakPointSmall = "580px"
const breakPointMedium = "680px"
const breakPointLarge = "960px"

const Container = styled.header`
  margin-bottom: 1.5rem;
`

const Inner = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 2.6rem 1.0875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.lessThan(breakPointLarge)`
    padding: 1.0875rem 1.0875rem;
  `}

  ${media.lessThan(breakPointSmall)`
    padding-right: calc(1.0875rem - 5px);
  `}
`

const LogoWrapper = styled.div`
  margin-right: 30px;
  h1 {
    margin: 0;
  }
  a {
    color: #fb251b;
    text-decoration: none;
    font-size: 25px;
  }

  ${media.lessThan(breakPointMedium)`
     margin-right: 5px;
  `}
`

const Logo = () => (
  <LogoWrapper>
    <h1>
      <Link to="/">Quak</Link>
    </h1>
  </LogoWrapper>
)

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 15px;

  ${media.lessThan(breakPointSmall)`
    display: none;
  `}

  ${props =>
    props.cta &&
    css`
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    `}
`

const ListItem = styled.li`
  margin-bottom: 0;
  margin-left: 35px;
  text-transform: uppercase;
  letter-spacing: 0.02em;

  ${media.lessThan(breakPointMedium)`
     margin-left: 25px;
  `}
`

const MenuLink = styled(Link)`
  color: black;
  border-bottom: 2px solid #eee;
  padding-bottom: 4px;
  padding-left: 3px;
  padding-right: 3px;
  transition: border-bottom 100ms ease-in-out;

  &:hover {
    border-bottom-color: #ccc;
    text-decoration: none;
    color: black;
  }

  &.active {
    border-bottom-color: black;
  }
`

const ListItemLink = props => (
  <ListItem>
    <MenuLink to={props.to} activeClassName="active" partiallyActive={true}>
      {props.children}
    </MenuLink>
  </ListItem>
)

const External = styled.a`
  border-bottom: 2px solid #eee;
  color: black;
  padding-bottom: 4px;
  padding-left: 3px;
  padding-right: 3px;
  transition: border-bottom 100ms ease-in-out;

  &:hover {
    border-bottom-color: #ccc;
    text-decoration: none;
  }

  ${props =>
    props.signup &&
    css`
      padding: 0;
      border-bottom: 0;
      background-color: #fb251b;
      padding: 8px 14px;
      color: white;
      transition: background-color 100ms ease-in-out;
      border-radius: 5px;

      &:hover {
        border-bottom: 0;
        background-color: #333;
        color: white;
      }
    `}
`

const ExternalLink = props => (
  <ListItem>
    <External signup={props.signup} href={props.url} target="_blank">
      {props.children}
    </External>
  </ListItem>
)

const Burger = styled.div`
  display: none;
  cursor: pointer;
  font-size: 28px;
  padding: 5px;
  line-height: 0.8;

  &:hover {
    color: #fb251b;
  }

  ${media.lessThan(breakPointSmall)`
    display: block;
  `}
`

const NavigationHeader = props => {
  return (
    <Container>
      <Inner>
        <Logo />

        <List>
          <ListItemLink to="/clients/">Clients</ListItemLink>
          <ListItemLink to="/api/">API</ListItemLink>
          <ListItemLink to="/pricing/">Pricing</ListItemLink>
          <ListItemLink to="/faq/">FAQ</ListItemLink>
        </List>

        <List cta>
          <ExternalLink url="https://app.quak.sh?login">Log in</ExternalLink>
          <ExternalLink signup url="https://app.quak.sh?signup">
            Sign up
          </ExternalLink>
        </List>

        <Burger
          onClick={() => {
            props.setMobileNavVisibility(true)
          }}
        >
          <FiMenu />
        </Burger>
      </Inner>
    </Container>
  )
}

NavigationHeader.propTypes = {
  setMobileNavVisibility: PropTypes.func.isRequired,
}

export default NavigationHeader
