/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { CookiesProvider, useCookies } from "react-cookie"
import styled from "styled-components"
import classNames from "classnames"
import media from "styled-media-query"

import Navigation from "./navigation"
import Footer from "./footer"
import ApiKeyContext from "../context/api-key-context"

const API_KEY_COOKIE_NAME = "testApiKey"

const Content = styled.div`
  margin: 0 auto;
  margin-bottom: 50px;
  max-width: 960px;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 0;

  &.sidebar {
    display: flex;
    justify-content: space-between;

    ${media.lessThan("medium")`
      flex-direction: column-reverse;
    `}
  }
`

const Main = styled.main`
  flex-grow: 1;
  min-width: 0;

  /* h2:first-of-type {
    margin-top: 0;
  } */
`

const Sidebar = styled.nav`
  flex-shrink: 0;
  margin-left: 50px;
  width: 200px;
  align-self: self-start;

  > div {
    padding: 20px 30px;
    border: 1px solid #dadada;
    border-radius: 10px;
  }

  ${media.lessThan("medium")`
    margin-left: 0;
    margin-bottom: 30px;
    width: 100%;
  `}
`

const TemplateSidebar = props => (
  <Sidebar>
    <div>{props.children}</div>
  </Sidebar>
)

const Title = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0px 1.0875rem 1.6rem;
  padding-top: 0;

  > h1 {
    margin: 0;
  }
`

const Layout = props => {
  const [cookies, setCookie, removeCookie] = useCookies([API_KEY_COOKIE_NAME])

  return (
    <CookiesProvider>
      <ApiKeyContext.Provider
        value={{
          apiKey: cookies[API_KEY_COOKIE_NAME],
          setApiKey: key => {
            if (key) {
              setCookie(API_KEY_COOKIE_NAME, key, { path: "/" })
            } else {
              removeCookie(API_KEY_COOKIE_NAME, { path: "/" })
            }
          },
        }}
      >
        <Navigation />

        {props.title && (
          <Title>
            <h1>{props.title}</h1>
          </Title>
        )}

        <Content
          className={classNames({
            sidebar: props.sidebar,
          })}
        >
          <Main>{props.children}</Main>
          {props.sidebar && <TemplateSidebar>{props.sidebar}</TemplateSidebar>}
        </Content>
        <Footer />
      </ApiKeyContext.Provider>
    </CookiesProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default Layout
