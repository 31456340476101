import { useStaticQuery, graphql } from "gatsby"

export const useVoices = () => {
  const data = useStaticQuery(graphql`
    query QueryVoices {
      allQuakVoices {
        nodes {
          slug
          name
          locale
          language
          languageName
          languageNativeName
          gender
          provider
          credits
        }
      }
    }
  `)

  return data.allQuakVoices.nodes
}
