import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

const breakPoint = "580px"

const Container = styled.footer`
  background-color: #333;
  color: white;
`

const Inner = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 2rem 1.0875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  ${media.lessThan(breakPoint)`

    font-size: 15px;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 15px;
  `}
`

const LegalNote = styled.div`
  color: #aaa;

  ${media.lessThan(breakPoint)`
    margin-bottom: 25px;
  `}
`

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;

  ${media.lessThan(breakPoint)`
    flex-direction: column;
    margin-bottom: 10px;
  `}
`

const ListItem = styled.li`
  margin-bottom: 0;
  margin-left: 25px;

  ${media.lessThan(breakPoint)`
    margin-bottom: 10px;
    margin-left: 0;
  `}
`

const ListItemLink = styled(Link)`
  color: #aaa !important;

  &:hover {
    text-decoration: none;
    color: white !important;
  }
`

const SmallLink = props => (
  <ListItem>
    <ListItemLink to={props.to}>{props.children}</ListItemLink>
  </ListItem>
)

const Footer = () => (
  <Container>
    <Inner>
      <LegalNote>© {new Date().getFullYear()} Quak</LegalNote>
      <List>
        <SmallLink to="/legal/">Legal Notice</SmallLink>
        <SmallLink to="/privacy/">Privacy Policy</SmallLink>
        <SmallLink to="/terms/">Terms of Service</SmallLink>
      </List>
    </Inner>
  </Container>
)

export default Footer
