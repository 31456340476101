import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useVoices } from "../../hooks/use-voices"

const VoicePage = () => {
  const voiceList = useVoices()

  return (
    <Layout title="Voices">
      <SEO title="Voices" keywords={[`sonos`, `audio`, `notifications`]} />

      <table>
        <thead>
          <tr>
            <th>Slug</th>
            <th>Locale</th>
            <th>Gender</th>
            <th>Provider</th>
            <th>Credits</th>
          </tr>
        </thead>
        <tbody>
          {voiceList.map((voice, index) => (
            <tr key={index}>
              <td>
                <code>{voice.slug}</code>
              </td>
              <td>{voice.locale}</td>
              <td>{voice.gender}</td>
              <td>{voice.provider}</td>
              <td>{voice.credits}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <p>
        List of all <Link to="/voices/languages/">languages</Link> and{" "}
        <Link to="/voices/locales/">locales</Link> where voices are available.
      </p>
    </Layout>
  )
}

export default VoicePage
